import React from 'react';
import { navigate } from 'gatsby-link';
import Layout from '../../components/Layout';
let axios = require('axios');
let sessionstorage = require('sessionstorage');

class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state={
        username:'',
        password:''
    }
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  componentDidMount(){
    this.wesdata=JSON.parse(sessionstorage.getItem('wesdata'));
    if(!this.wesdata){
      navigate("/login")
    }else{
        this.setState({ username: this.wesdata.username })
    }
  }

  render() {
      return (
        <Layout>
          <section className="section" >
            <div className="container">
              <div className="content">
                <h1>Profile</h1>
                <form 
                name="profile"
                method="post"
                action="/profile"
                onSubmit={this.handleSubmit}
                >

                    <div className="field">
                        <label htmlFor="username">
                            Email/Username
                        </label>
                        <div className="control">
                            <input
                            className="input"
                            type="email"
                            name="username"
                            id="username"
                            onChange={this.handleChange}
                            required={true}
                            value={this.state.username}
                            />
                        </div>
                    </div>

                    <div className="field">
                        <label htmlFor="password">
                            Password
                        </label>
                        <div className="control">
                            <input
                            className="input"
                            type="password"
                            name="password"
                            id="password"
                            onChange={this.handleChange}
                            required={true}
                            placeholder="Enter new password or leave empty"
                            />
                        </div>
                    </div>

                </form>
              </div>
            </div>
          </section>
        </Layout>
      );
  }
}

export default Profile;
